import React from 'react'
import './ReportUsage.css'
import RentalImg from '../assets/images/rentals.png'
import AutoLoanImg from '../assets/images/auto-loan.png'
import BackgroundCheckImg from '../assets/images/background-check.png'
import TaxesImg from '../assets/images/taxes.png'

function ReportUsage() {
  return (
    <div className='usage-container'>
      <h2 className='usage-header supria-sans-black-font'>
        Where can I use it?
      </h2>
      <p className='usage-text inter-font'>
        Share your Gig Income Verification reports anytime you need to prove
        your income
      </p>
      <div className='usage-wrapper'>
        <div className='report-usage-examples-container'>
          <div className='usage-case-container'>
            <img
              src={RentalImg}
              className='usage-img'
              alt='Rental Properties'
            />
            <p className='usage-title'>Rental Properties</p>
          </div>
          <div className='usage-case-container'>
            <img src={AutoLoanImg} className='usage-img' alt='Auto Loans' />
            <p className='usage-title'>Auto Loans</p>
          </div>
          <div className='usage-case-container'>
            <img
              src={BackgroundCheckImg}
              className='usage-img'
              alt='Background Checks'
            />
            <p className='usage-title'>Background Checks</p>
          </div>
          <div className='usage-case-container'>
            <img src={TaxesImg} className='usage-img' alt='Filing Taxes' />
            <p className='usage-title'>Filing Taxes</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReportUsage
