import React, { useState } from 'react'
import './IncomeReport.css'
import ReportImg from '../assets/images/report.png'
import ReportCheckImg from '../assets/images/report-check.png'
import { validateEmail, openArgyle, hasValidArgyleId } from '../utils/helpers'

function IncomeReport() {
  const [inputs, setInputs] = useState({
    email: '',
    marketingOpt: true,
  })
  const [emailError, setEmailError] = useState('')
  const [showEmailError, setShowEmailError] = useState(false)

  const validateEmailInput = (email) => {
    if (validateEmail(email)) return true
    setEmailError('Please enter a valid email address')
    return false
  }

  const handleEmailInput = (e) => {
    setInputs((inputs) => ({ ...inputs, [e.target.name]: e.target.value }))
    if (validateEmailInput(e.target.value)) {
      setEmailError('')
      setShowEmailError(false)
      localStorage.setItem('email', e.target.value)
    } else {
      localStorage.setItem('email', e.target.value)
    }
  }

  const handleMarketingOpt = (e) => {
    setInputs((inputs) => ({ ...inputs, marketingOpt: e.target.checked }))
  }
  const handleSubmit = (e) => {
    e.preventDefault()
    if (!validateEmailInput(inputs.email)) {
      setEmailError('Please enter a valid email address')
      setShowEmailError(true)
    } else if (inputs.email && validateEmailInput(inputs.email)) {
      localStorage.setItem('email', inputs.email)
      localStorage.setItem('marketingConsent', inputs.marketingOpt)
      openArgyle()
    }
  }

  const getIncomeReportContent = () => {
    if (hasValidArgyleId(localStorage.getItem('argyleUserId'))) {
      return (
        <div className='income-report-box'>
          <div className='report-img-check-container'>
            <img
              className='report-img-check'
              src={ReportCheckImg}
              alt='Report Check Mark'
            />
          </div>
          <div className='form-head-container'>
            <h2 className='form-header supria-sans-black-font'>We’re On It!</h2>
            <p className='form-text inter-font'>
              We’ll send your gig income verification report within the next
              hour to:
              <br />
              <span className='user-email'>
                {localStorage.getItem('email')}
              </span>
            </p>
          </div>
          <div>
            <button
              className='edit-report-btn  rubik-font'
              type='button'
              onClick={() => openArgyle()}
            >
              EDIT MY INCOME REPORT
            </button>
          </div>
          <div className='share-note'>
            <h2 className='share-header supria-sans-black-font'>
              Did you find this helpful?
            </h2>
            <p className='share-text inter-font'>
              Share this tool with other gig workers!
            </p>
          </div>
        </div>
      )
    }

    return (
      <div className='income-report-box'>
        <div className='report-img-container'>
          <img className='report-img' src={ReportImg} alt='Report' />
        </div>
        <div className='form-head-container'>
          <h2 className='get-report-header supria-sans-black-font'>
            Get your FREE report now
          </h2>
          <p className='get-report-text inter-font'>
            What email address should we send your Gig Income Report to?
          </p>
        </div>
        <div>
          <form className='report-form' onSubmit={handleSubmit}>
            <div>
              <input
                type='text'
                className={`email-input-form ${
                  showEmailError ? 'invalid-input' : 'valid-input'
                } inter-font`}
                placeholder='Enter your email address'
                id='email'
                name='email'
                onChange={(e) => {
                  handleEmailInput(e)
                }}
                value={inputs.email}
                autoComplete='off'
              />
              {showEmailError && (
                <p className='email-error supria-sans-regular-font'>
                  {emailError}
                </p>
              )}
            </div>
            <div>
              <input
                className='get-report-btn supria-sans-regular-font'
                type='submit'
                value='START MY REPORT'
              />
            </div>
            <div className='agreement-acceptance'>
              <input
                type='checkbox'
                className='agreement-check-btn'
                name='agreementAcceptance'
                defaultChecked
                onClick={(e) => {
                  handleMarketingOpt(e)
                }}
              />
              <p className='agreement-check-text inter-font'>
                Yes, tell me about other tools and news from Moves. No spam and
                we never sell your data.
              </p>
            </div>
          </form>
        </div>
      </div>
    )
  }
  return <div>{getIncomeReportContent()}</div>
}

export default IncomeReport
