import React from 'react'
import './Intro.css'
import ReportPic from '../assets/images/iv-report.png'

function Intro() {
  return (
    <div className='intro-container'>
      <div>
        <h1 className='intro-header supria-sans-black-font'>
          Income Verification for Gig Workers
        </h1>
        <p className='intro-text inter-font'>
          Generate a verified report of all your gig earnings for a lender,
          landlord or background check company.
        </p>
      </div>
      <div>
        <img src={ReportPic} className='report-pic' alt='Report' />
      </div>
    </div>
  )
}

export default Intro
