import React from 'react'
import './SupportedPlatforms.css'
import AliceCarousel from 'react-alice-carousel'
import 'react-alice-carousel/lib/alice-carousel.css'
import AngiLogo from '../assets/images/gigLogos/angi_services.png'
import AmazonflexLogo from '../assets/images/gigLogos/amazon_flex.png'
import CornershopLogo from '../assets/images/gigLogos/cornershop.png'
import DoordashLogo from '../assets/images/gigLogos/doordash.png'
import RoadieLogo from '../assets/images/gigLogos/roadie.png'
import GoPuffLogo from '../assets/images/gigLogos/gopuff.png'
import LyftLogo from '../assets/images/gigLogos/lyft.png'
import InstacartLogo from '../assets/images/gigLogos/instacart.png'
import UberLogo from '../assets/images/gigLogos/uber.png'

function SupportedPlatforms() {
  const handleDragStart = (e) => e.preventDefault()

  const responsive = {
    0: { items: 5 },
    420: { items: 5 },
    960: { items: 8 },
  }

  const logos = [
    <img
      src={AngiLogo}
      alt='Angi Services'
      onDragStart={handleDragStart}
      role='presentation'
      className='logo'
    />,
    <img
      src={AmazonflexLogo}
      alt='Amazon Flex'
      onDragStart={handleDragStart}
      role='presentation'
      className='logo'
    />,
    <img
      src={CornershopLogo}
      alt='Cornershop'
      onDragStart={handleDragStart}
      role='presentation'
      className='logo'
    />,
    <img
      src={DoordashLogo}
      alt='DoorDash'
      onDragStart={handleDragStart}
      role='presentation'
      className='logo'
    />,
    <img
      src={RoadieLogo}
      alt='Roadie'
      onDragStart={handleDragStart}
      role='presentation'
      className='logo'
    />,
    <img
      src={GoPuffLogo}
      alt='GoPuff'
      onDragStart={handleDragStart}
      role='presentation'
      className='logo'
    />,
    <img
      src={LyftLogo}
      alt='Lyft'
      onDragStart={handleDragStart}
      role='presentation'
      className='logo'
    />,
    <img
      src={InstacartLogo}
      alt='Instacart'
      onDragStart={handleDragStart}
      role='presentation'
      className='logo'
    />,
    <img
      src={UberLogo}
      alt='Uber'
      onDragStart={handleDragStart}
      role='presentation'
      className='logo'
    />,
  ]
  return (
    <div className='supported-platforms-container'>
      <p className='supported-platforms-header inter-font'>
        Supports income from 20+ gig companies
      </p>
      <div className='supported-platforms-carousel'>
        <AliceCarousel
          animationType='glide'
          animationDuration={2000}
          animationEasingFunction='linear'
          autoPlay='true'
          responsive={responsive}
          mouseTracking
          items={logos}
          disableDotsControls
          disableSlideInfo
          infinite
          renderPrevButton='false'
        />
      </div>
    </div>
  )
}

export default SupportedPlatforms
