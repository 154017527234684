import React from 'react'
import './NotFound.css'
import NotFoundImg from '../assets/images/404-not-found.png'

function NotFound() {
  return (
    <div className='not-found-container'>
      <img src={NotFoundImg} className='not-found-img' alt='Not Found' />
      <h1 className='not-found-header supria-sans-black-font'>
        Oops. Looks like you took a wrong turn.
      </h1>
      <p className='not-found-text inter-font'>
        The page you are looking for does not exist. Go{' '}
        <a
          className='not-found-link'
          target='_self'
          rel='noopener noreferrer'
          href='https://verify.movesfinancial.com/'
        >
          here
        </a>{' '}
        to get moving in the right direction.
      </p>
    </div>
  )
}

export default NotFound
