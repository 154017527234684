import React from 'react'
import './Home.css'
import Intro from '../components/Intro'
import IncomeReport from '../components/IncomeReport'
import SupportedPlatforms from '../components/SupportedPlatforms'
import HowItWorks from '../components/HowItWorks'
import ReportUsage from '../components/ReportUsage'
import Faq from '../components/Faq'

function Home() {
  return (
    <div className='home-container'>
      <Intro />
      <IncomeReport />
      <SupportedPlatforms />
      <HowItWorks />
      <ReportUsage />
      <Faq />
    </div>
  )
}

export default Home
