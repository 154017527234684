import React from 'react'
import './AppBar.css'
import Logo from '../assets/images/moves-verify.png'

function AppBar() {
  const homeText = '<- Back to Moves'
  return (
    <div className='app-bar-container'>
      <div className='app-bar-navigation'>
        <div>
          <img src={Logo} className='moves-logo' alt='Moves logo' />
        </div>
        <div>
          <a
            className='app-bar-moves-link supria-sans-bold-font'
            target='_self'
            rel='noopener noreferrer'
            href='https://movesfinancial.com/'
          >
            {homeText}
          </a>
        </div>
      </div>
    </div>
  )
}

export default AppBar
