import React from 'react'
import './Footer.css'
import LoveImg from '../assets/images/love.png'

function Footer() {
  return (
    <div className='footer'>
      <div>
        <img src={LoveImg} className='footer-img' alt='Heart' />
      </div>
      <div>
        <p className='footer-header supria-sans-black-font'>
          Gig work is real work.
        </p>
      </div>
      <div>
        <p className='footer-text inter-font'>
          Spread the word and help other gig workers generate gig income
          verification reports.
        </p>
      </div>
      <div>
        <p className='footer-text inter-font'>©2023 Moves</p>
      </div>
    </div>
  )
}

export default Footer
