import React from 'react'
import './HowItWorks.css'
import Step1 from '../assets/images/step-1-login.png'
import Step2 from '../assets/images/step-2-connect.png'
import Step3 from '../assets/images/step-3-get-report.png'

function HowItWorks() {
  return (
    <div className='how-it-works-container'>
      <h2 className='how-it-works-header supria-sans-black-font'>
        How does it work?
      </h2>
      <p className='header-text supria-sans-regular-font'>
        Simply connect your gig accounts and we’ll generate a verified PDF
        document of your earnings.
      </p>
      <a
        className='sample-link supria-sans-regular-font'
        target='_blank'
        rel='noopener noreferrer'
        href='https://storage.googleapis.com/moves-voie/Argyle_Income_and_Employment_Verification_Sample_Report.png'
      >
        VIEW SAMPLE REPORT
      </a>
      <div className='steps-container'>
        <div className='step-wrap'>
          <img src={Step1} className='step-img' alt='Step 1' />
          <h3 className='step-header supria-sans-regular-font'>
            1. Enter your email address
          </h3>
          <p className='step-text inter-font'>
            We’ll send your official gig income verification as a PDF to this
            address.
          </p>
        </div>
        <div className='step-wrap'>
          <img src={Step2} className='step-img' alt='Step 2' />
          <h3 className='step-header supria-sans-regular-font'>
            2. Connect your gig apps
          </h3>
          <p className='step-text inter-font'>
            Securely log in to any of your gig accounts to include your earnings
            in the report.
          </p>
        </div>
        <div className='step-wrap'>
          <img src={Step3} className='step-img' alt='Step 3' />
          <h3 className='step-header supria-sans-regular-font'>
            3. Get your gig income report
          </h3>
          <p className='step-text inter-font'>
            You’ll receive a PDF Income Verification report within the hour.
            This includes a summary of all your earnings organized by year.
          </p>
        </div>
      </div>
    </div>
  )
}

export default HowItWorks
