import localConfig from '../envs/local.json'

/**
 * Validates given email
 * @param email
 * @return boolean
 */
export const validateEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  return re.test(String(email.trim()).toLowerCase())
}

/**
 * Validates if a user has valid Argyle Id
 * @param id
 * @return boolean
 */

export const hasValidArgyleId = (id) =>
  id !== null && id !== undefined && id !== '' && id.length > 2

export const getReportId = () => {
  const postVOIEReportId = {
    method: 'POST',
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
      authorization: `${localConfig.VOIE_AUTHORIZATION_TOKEN}`,
    },
    body: JSON.stringify({
      email: `${localStorage.getItem('email')}`,
      argyleUserId: `${localStorage.getItem('argyleUserId')}`,
      marketingConsent: `${localStorage.getItem('marketingConsent')}`,
    }),
  }

  fetch(`${localConfig.API_URL}/argyle-voie/v1/reports`, postVOIEReportId)
    .then((response) => response.json())
    .catch((err) => console.error(err))
}

let argyle

export function openArgyle() {
  if (argyle !== undefined) {
    argyle.open()
  } else {
    console.log('Argyle is not ready')
  }
}

function createArgyle(userToken) {
  const argyleCreateOptions = {
    linkKey: `${localConfig.ARGYLE_LINK_KEY}`,
    apiHost: `${localConfig.ARGYLE_API_HOST}`,
    onAccountCreated: ({ accountId, userId, linkItemId }) => {
      localStorage.setItem('argyleUserId', userId)
    },
    onAccountConnected: ({ accountId, userId, linkItemId }) => {},
    onAccountUpdated: ({ accountId, userId, linkItemId }) => {},
    onAccountRemoved: ({ accountId, userId, linkItemId }) => {},
    onUserCreated: ({ userId, userToken }) => {
      localStorage.setItem('argyleUserToken', userToken)
    },
    onClose: () => {
      getReportId()
      window.location.reload()
    },
    onTokenExpired: (updateToken) => {},
  }

  if (userToken !== undefined) {
    argyleCreateOptions.userToken = userToken
  }
  // eslint-disable-next-line no-undef
  argyle = Argyle.create(argyleCreateOptions)
}

function getArgyleUserTokenAndCreateArgyle() {
  const options = {
    method: 'POST',
    headers: {
      accept: 'application/json',
      'content-type': 'application/json',
      authorization: `Basic ${localConfig.ARGYLE_AUTHORIZATION_TOKEN}`,
    },
    body: JSON.stringify({ user: localStorage.getItem('argyleUserId') }),
  }

  fetch(`${localConfig.ARGYLE_API_HOST}/user-tokens`, options)
    .then((response) => response.json())
    .then((response) => {
      createArgyle(response.access)
    })
    .catch((err) => console.error(err))
}

export function initArgyle() {
  const argyleUserId = localStorage.getItem('argyleUserId')

  if (hasValidArgyleId(argyleUserId)) {
    getArgyleUserTokenAndCreateArgyle()
  } else {
    // Create Argyle without user token for new user
    createArgyle(undefined)
  }
}
