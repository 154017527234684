import React from 'react'
import './Faq.css'
import FaqImg from '../assets/images/faq.png'

function Faq() {
  return (
    <div className='faq-container'>
      <img src={FaqImg} className='faq-img' alt='Heart' />
      <h2 className='faq-header supria-sans-black-font'>FAQ</h2>
      <h3>Are Gig Income Verification Reports with Moves free?</h3>
      <p>
        Absolutely. Generating your Gig Income Verification report is completely
        free.
      </p>
      <h3>How will I receive my report?</h3>
      <p>
        Once you have made a request, you will receive an email to download your
        Income Verification Report within 1 hour.
      </p>
      <h3>Can I really use this as proof of employment or income?</h3>
      <p>
        It is becoming more common for gig workers to use gig earnings as proof
        of income. However it may depend on the specific context in which income
        verification is requested. Showing multi-year earnings alongside prior
        1099 tax return documents may help alleviate reluctancy from requesting
        parties.
      </p>
      <p>
        To note, it is recommended to check with the institution or organization
        in question to understand their specific requirements and whether or not
        they accept earnings from gig apps as proof of income.
      </p>
    </div>
  )
}

export default Faq
