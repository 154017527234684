import React from 'react'
import './VOIEReport.css'
import { useLocation } from 'react-router-dom'
import { BounceLoader } from 'react-spinners'
import localConfig from '../envs/local.json'

function VOIEReport() {
  const location = useLocation()
  const reportToken = location.pathname.split('/').pop()
  const getReportURL = () => {
    const getVOIEReportURL = {
      method: 'GET',
      headers: {
        accept: 'application/json',
        'content-type': 'application/json',
        authorization: `${localConfig.VOIE_AUTHORIZATION_TOKEN}`,
      },
    }

    fetch(
      `${localConfig.API_URL}/argyle-voie/v1/reports/token/${reportToken}`,
      getVOIEReportURL,
    )
      .then((response) => response.json())
      .then((response) => {
        setTimeout(() => {
          window.open(`${response.report.file_url}`, '_self')
        }, 1000)
      })
      .catch((err) => console.error(err))
  }
  getReportURL()

  return (
    <div className='loading-container'>
      <div className='spinner-container'>
        <BounceLoader color='#E7F9EC' />
      </div>
      <h1 className='loading-header supria-sans-black-font'>Loading...</h1>
      <p className='loading-text supria-sans-regular-font'>
        We’re bring up your Gig Income Verification Report
      </p>
    </div>
  )
}

export default VOIEReport
