import React from 'react'
import './App.css'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import Home from './pages/Home'
import AppBar from './components/AppBar'
import VOIEReport from './pages/VOIEReport'
import NotFound from './pages/NotFound'
import Footer from './components/Footer'
import { initArgyle } from './utils/helpers'

function App() {
  initArgyle()
  return (
    <Router>
      <AppBar />
      <main className='main-content'>
        <Routes>
          <Route exact path='/' element={<Home />} />
          <Route path='/token/:token' element={<VOIEReport />} />
          <Route path='*' element={<NotFound />} />
        </Routes>
      </main>
      <Footer />
    </Router>
  )
}

export default App
